.toolTip {
  display: flex;
  position: absolute;
  background-color: transparent;

  animation: fadein 400ms ease-in;
  z-index: 11;

  &__tail {
    position: absolute;
    top: -6px;
    left: 0;

    &.up {
      top: 100%;
      transform: rotate(180deg);
    }

    &.left {
      left: calc(84% - 8px);
    }

    &.right { 
      left: calc(16% - 8px)
    }
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    cursor: default;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
    z-index: 10;
  }
}
