.alertMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  max-width: 534px;
  margin-bottom: 24px;
  padding: 20px 32px;
  
  box-shadow: 0 12px 48px -16px rgba(33, 160, 56, 0.45);
  border-radius: 12px;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.005em;
  color: #FFFFFF;
  
  animation: fadein 0.2s ease-in alternate;

  &.title {
    align-items: flex-start;
  }

  &.alarm {
    background-color: #F54242;
  }

  &.info {
    background-color: #2CBA5F;
  }

  & > h2 {
    margin-right: 16px;
  }

  & > p {
    white-space: pre-line;
  }

  & > p.title {
    margin-top: 8px;
  }

  & > svg {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
  }

  & > svg path {
    fill: #FFFFFF;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}
