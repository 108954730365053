.connectionsContainer {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  padding: 32px 48px 40px 48px;
  border-radius: 0 0 0 32px;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.20);

  & > h1 {
    margin-bottom: 24px;
  }

  .connectionsContent {
    overflow-y: auto;

    & > h3 {
      margin-bottom: 16px;
    }
  }

  &.guideOn {
    .connectionsContent {
      overflow-y: hidden;
    }
  }
}
