.messaging > p + p {
  margin-top: 16px;
}

.messaging {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 32px 48px 40px 48px;
  border-radius: 32px 0 0 32px;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.20);

  &__footer {
    display: flex;
    flex-direction: column;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.09px;

    margin-top: 20px;

    &_switch {
      display: inline-flex;
    }

    .GlobalButton {
      padding: 14px 16px;
      margin-top: 40px;
    }
  }
}
