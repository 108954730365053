.promotionBanner {
  display: flex;
  flex-direction: column;

  a {
    cursor: pointer;
    font-size: 12px;
    color: #fe6d04;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.07px;
  }
}
