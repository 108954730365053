.tagItem {
  display: grid;
  grid-template-columns: minmax(20%, 196px) auto;
  column-gap: 30px;
  border-bottom: 2px solid #E6E6EA;
  border-radius: 2px;
  padding: 16px 0;

  &__name {

    &_container {
      width: fit-content;
      padding: 4px 8px;
      border-radius: 6px;
      box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.40);
    }
  }

  &__description{
    display: flex;
    justify-content: space-between;
  }

  #edit {
    padding: 6px 8px;

    path {
      fill: #FE6D04;
    }
  }
}
