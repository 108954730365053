.integrationDescription {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 32px 48px 40px 48px;
  border-radius: 32px 0 0 32px;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.20);

  h1 {
    margin-bottom: 16px;
  }

  &__attention {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    .regularText.semibold {
      display: flex;
    }

    & > p {
      white-space: pre;
      color: #F54242;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;

        path {
          fill: #F54242;
        }
      }

      & > span {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    & .amoButton {
      cursor: pointer;
      outline: none;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 314px;
      height: 56px;
      margin-top: 16px;
      border-radius: 10px;
      background-color: #394f70;
      border: 1px solid rgb(0, 111, 159);
      color: #ffffff;

      & > img {
        height: 100%;
        border-radius: 10px 0 0 10px;
      }

      & > span {
        text-transform: uppercase;
        width: 100%;
      }
    }
  }

  &__text {
    h2 {
      margin-bottom: 10px;
      margin-top: 10px;
    }

    li {
      font-family: Inter, serif;
      font-style: normal;
      font-weight: normal;
    }
  }

  &__Description {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
  }

  .amocrm_install_button {
    display: inline-flex;
    align-items: center;
    margin-bottom: 25px;
    border-radius: 10px;
    overflow: hidden;
    height: 58px !important;
    background-color: #394f70 !important;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 13px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;

    .amocrm_install_button_div:first-child {
      padding: 0 8px !important;
    }

    .amocrm_install_button_div {
      height: 100%;
      display: flex;
      background: #006f9f;
      align-items: center;
    }

    span {
      display: inline-block;
      font-family: 'Roboto', sans-serif;
      font-style: normal;

      letter-spacing: -0.005em;
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-transform: none !important;
      color: #ffffff;
    }
  }

  & > * ol {
    margin-bottom: 12px;
  }

  & > * ol > li + li {
    margin-top: 20px;
  }

  & > * a {
    text-decoration: underline;
    color: #FE6D04;
  }

  .selectedCountry {
    width: 458px;

    & > * {
      margin-top: 12px;
    }
  }

  &__form {
    margin-bottom: 48px;

    .GlobalButton {
      padding: 14px 16px;
    }
  }
}
