.alertContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 30px;
  left: 0;
  height: 1px;
  width: 100%;
  z-index: 10000;
}
