.onboardingWizard {
  position: relative;
  width: 480px;
  height: fit-content;
  top: 120px;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
  linear-gradient(0deg, #F2F2F2, #F2F2F2);
  border: 1px solid #F2F2F2;
  border-radius: 12px;
  padding: 20px 32px 24px 32px;
  box-shadow: 0 2.767px 2.214px 0 rgba(0, 0, 0, 0.02), 0 6.65px 5.32px 0 rgba(0, 0, 0, 0.03), 0 12.522px 10.017px 0 rgba(0, 0, 0, 0.04), 0 22.336px 17.869px 0 rgba(0, 0, 0, 0.04), 0 41.778px 33.422px 0 rgba(0, 0, 0, 0.05), 0 100px 80px 0 rgba(0, 0, 0, 0.07);
  z-index: 102;

  &__header {

    .minusIcon {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }

    h2 {
      margin-bottom: 16px;
    }
  }
}
