.switchTabs {
  padding: 3px;
  display: flex;
  align-items: center;
  height: 44px;
  background: #F9F9F9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: fit-content;

  & button {
    width: max-content;
    display: flex;
    align-items: center;
    padding: 7px 9px 7px 7px;
    margin-right: 15px;
    margin-left: 2px;
    background-color: #F9F9F9;
    border: none;
    outline: none;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    color: #4F4F4F;
    cursor: pointer;

    &:last-child {
      margin-right: 4px;
    }

    & svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    &.active {
      background: #FE6D04;
      box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.15);
      border-radius: 7px;
      color: #FFFFFF;

      & svg path {
        fill: #FFFFFF
      }
    }
  }
}
