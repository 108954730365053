.wabaTextarea {

  &__label {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 500;

    &.error {
      & > textarea {
        border-radius: 6px;
        border: 3px solid #F54242;
      }
    }
  }

  & > textarea {
    display: flex;
    width: 464px;
    height: 104px;
    margin-top: 12px;
    margin-bottom: 24px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    background: #F4F9FF;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    border: none;
    font-family: Inter;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.09px;
    padding-left: 16px;
    padding-top: 16px;
    padding-right: 13px;
    resize: none;
    outline: none;
    color: #333333;
  }

  & .error {
    color: #F54242;
    margin-bottom: 12px;
  }
}
