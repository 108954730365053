.instruction {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.09px;
  font-feature-settings: 'cv05' on, 'cv02' on, 'cv01' on, 'cv09' on;

  & > h3 {
    margin-bottom: 16px;
  }

  & a {
    text-decoration: none;
    color: #FE6D04;

    &:hover {
      text-decoration: underline;
    }
  }
  
  & > ol > li + li {
    margin-top: 16px;
  }
  
  & > ol:last-of-type {
    counter-reset: steps 1;
    margin-top: 8px;
    margin-bottom: 24px;
  }
}
