@import 'src/mixin.scss';

.listiconContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  & input {
    display: none;
  }

  .editForm__date {

    @include desktop {
      display: none;
    }
    @include mobile {
      display: inline-flex;
      margin-bottom: 16px;
    }
  }

  &__label {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px;

    &__length {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin-left: 8px;
      background: #ffffff;
      box-shadow: 3px 2px -2px rgba(0, 0, 0, 0.25);
      border-radius: 12px;

      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on,
        'cpsp' on;

      color: #333333;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    height: 320px;
    padding: 12px;
    margin-right: 24px;
    overflow-y: scroll;
    background: #ffffff;
    box-shadow: inset 0 2px 3px -2px rgba(0, 0, 0, 0.45);
    border-radius: 12px;

    &__item {
      display: flex;
      justify-content: space-between;
      min-height: 48px;
      width: 100%;
      margin-bottom: 12px;
      border: 1px solid #f2f2f2;
      box-shadow: 0 2px 3px -2px rgba(0, 0, 0, 0.45);
      border-radius: 10px;

      & p {
        overflow: hidden;
      }

      .arrowIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        border: 1px solid #F2F2F2;
        background: #FFF;
        box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.20);

        svg {
          width: 12px;
          height: 12px;
        }
      }

      &.right {
        padding: 12px 12px 12px 16px;
        background: #fcfcfc;

        & p {
          text-align: start;
        }

        .arrowIcon svg {
          transform: rotateY(180deg);

          path {
            fill: #2CBA5F;
          }
        }
      }

      &.left {
        padding: 12px 16px 12px 12px;
        background: #f3fff8;

        & p {
          text-align: end;
        }

        .arrowIcon svg {
           path {
             fill: #F54242;
           }
        }
      }

      &:hover {
        background: #ffe1cc;
      }

      &_image {
        display: flex;
        cursor: pointer;
      }
    }
  }
}
