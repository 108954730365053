@import 'src/mixin.scss';
.modalWrapper {
  position: relative;
  top: 10%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  height: fit-content;
  max-height: 90%;
  overflow-y: scroll;
  padding: 20px 32px 32px 32px;
  box-shadow: 0 12px 48px -16px rgba(0, 0, 0, 0.65);
  border-radius: 12px;
  z-index: 110;
  
  &.minWidth {
    width: 534px;
  }

  @include mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    top: initial;
    right: initial;
    width: 100%;
    border-radius: 28px 28px 0 0;
  }

  &__container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 36px;
    box-sizing: border-box;
    z-index: 100;
    display: flex;
    justify-content: center;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    cursor: default;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;

    &.none {
      background-color: rgba(0, 0, 0, 0);
    }

    &.light {
      background: #333333;
      opacity: 0.3;
    }

    &.dark {
      background: black;
    }
  }

  & > svg {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 20px;
    height: 20px;
  }
}
