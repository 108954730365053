.select {
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;

  & > label {
    margin-bottom: 12px;
  }

  & > fieldset {
    border: none;
    position: relative;

    & > .logoIcon {
      position: absolute;
      left: 12px;
      top: 12px;
      width: 32px;
      height: 32px;
    }

    > input {
      cursor: pointer;
      outline: none;
      text-overflow: ellipsis;
      border: none;
      height: 56px;
      padding: 16px 12px 16px 16px;
      border-radius: 6px;
      background: #F4F9FF;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.20) inset;

      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.09px;
      color: #333333;

      &.logo {
        padding: 16px 12px 16px 52px;
      }
    }

    & > .arrowIcon {
      position: absolute;
      right: 12px;
      top: calc(50% - .5em);
      height: 20px;
      cursor: pointer;
      pointer-events: none;
      transition-duration: 300ms;
      transform: rotateX(0);

      svg {
        width: 20px;
        height: 20px;
      }

      &.opened {
        transition-duration: 300ms;
        transform: rotateX(180deg);
      }
    }

    & > ul {
      position: absolute;
      top: 52px;
      left: 0;
      max-height: 300px;
      width: 100%;
      margin-top: 8px;
      overflow-y: auto;
      text-decoration: none;
      z-index: 101;

      padding: 6px 2px 6px 2px;
      background: #F4F9FF;
      box-shadow: 0 6px 11px rgba(125, 123, 123, 0.04);
      border-radius: 4px;

      & > li {
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        padding: 8px 10px 10px 10px;
        border-radius: 6px;

        .logoIcon {
          width: 32px;
          height: 32px;
          margin-right: 10px;
        }

        &:hover {
          background: #FFE1CC;
        }

        &.selected {
          background: #FFE1CC;
        }
      }
    }
  }

  &.disabled {

    input {
      cursor: auto;
      border-radius: 6px;
      border: 1px solid #E6E6EA;
      background: #E0E0E0;
      box-shadow: none;
      color: #828282;
    }

    .arrowIcon svg path {
      fill: #BDBDBD;
    }
  }
}
