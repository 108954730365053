.goBackWrapper {
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;

  & > h1 {
    margin-bottom: 0 !important;
  }

  & > button {
    padding: 6px 12px 6px 8px;
    margin-right: 20px;

    svg path {
      fill: #FE6D04;
    }
  }

}
