.telegramBotForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 32px 48px 40px 48px;
  border-radius: 0 0 0 32px;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.20);

  & > p:last-of-type {
    margin-bottom: 32px;
  }

  .inputContainer__input {
    width: 400px;
  }

  & > .GlobalButton {
    padding: 14px 16px;
    margin-top: 25px;
  }

  & > .instruction {
    margin-top: 32px;
  }
}

.telegramBotForm > p + p {
  margin-top: 12px;
}
