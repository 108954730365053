@import 'src/mixin.scss';

:root {
  --field-desktop-width: 528px;
  --field-desktop-margin-bottom: 24px;
  --field-mobile-margin-bottom: 16px;
}

.settingCompany {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.20);

  & > h1 {
    display: flex;
    min-height: 44px;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -0.01em;
    @include desktop {
      margin-bottom: 24px;
    }
    @include mobile {
      width: 70%;
      margin-bottom: 22px;
    }
  }

  @include desktop {
    padding: 32px 48px 40px 48px;
    border-radius: 32px 0 0 32px;

    & > form {
      height: calc(100vh - 128px);
      display: flex;
      flex-direction: column;
    }

    & .inputContainer__input {
      width: var(--field-desktop-width);
    }

    & .dropdownContainer {
      width: var(--field-desktop-width);
      margin-bottom: var(--field-desktop-margin-bottom);
    }
  }

  @include mobile {
    padding: 20px 20px 24px 20px;

    & > form {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    & .inputContainer {
      width: 100%;
      margin-bottom: var(--field-mobile-margin-bottom);
      &__wrapper {
        width: 100%;
        & input {
          width: 100%;
        }
      }
    }

    & .dropdownContainer {
      width: 100%;
      margin-bottom: var(--field-mobile-margin-bottom);
    }
  }

  & .GlobalButton {
    padding: 14px 16px;
    margin-top: auto;

    @include mobile {
      width: 100%;
    }
  }
}
