.connectionAdd {
  display: flex;
  margin-top: 16px;

  .GlobalButton.small.white {
    padding: 6px 12px;

    svg path {
      fill: #FE6D04;
    }
  }

  &__select {
    display: flex;
    align-items: center;

    .GlobalButton {
      padding: 14px 16px;
    }

    .select {
      input {
        width: 312px;
      }
    }
  }

  &__select > * + * {
    margin-left: 16px;
  }
}
