.countrySelector {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 220px;
  z-index: 2;
  background: white;
  overflow-y: scroll;
  border: 1px darkgray solid;
  border-radius: 0 0 3px 3px;
  box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);

  & > li {
    display: inline-flex;
    align-items: center;
    list-style: none;
    cursor: pointer;
    line-height: 0.9em;
    padding: 8px 8px;

    & > img {
      margin-right: 8px;
    }
  }

  & > li.highlight, li:hover {
    background-color: #F1F1F1;
  }
}

.PhoneInput {
  position: relative;
}

.PhoneInputCountrySelector {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 8px;
  background: #F4F9FF;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 6px 0 0 6px;
}

.PhoneInputCountrySelectArrow {
  cursor: pointer;
}
