.dropdownContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &__error {
    margin-top: 8px;
    color: #F54242
  }

  & input {
    display: none;
  }

  &__label {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px;
    color: #333333;

    &.error {
      color: #F54242
    }
  }

  &__dropdown {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background: #F4F9FF;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    &.error {
      border: 3px solid #F54242;
    }

    &_placeholder {
      position: relative;
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
      overflow: hidden;
      white-space: nowrap;
      color: #828282;

      &:after {
        content: "";
        position: absolute;
        width: 32px;
        height: 24px;
        right: 0;
        background: linear-gradient(90deg, rgba(244, 249, 255, 0) 0%, rgba(244, 249, 255, 0.0086472) 6.81%, rgba(244, 249, 255, 0.03551) 13.61%, rgba(244, 249, 255, 0.0816599) 20.42%, rgba(244, 249, 255, 0.147411) 27.22%, rgba(244, 249, 255, 0.231775) 34.03%, rgba(244, 249, 255, 0.331884) 40.83%, rgba(244, 249, 255, 0.442691) 47.64%, rgba(244, 249, 255, 0.557309) 54.44%, rgba(244, 249, 255, 0.668116) 61.25%, rgba(244, 249, 255, 0.768225) 68.06%, rgba(244, 249, 255, 0.852589) 74.86%, rgba(244, 249, 255, 0.91834) 81.67%, rgba(244, 249, 255, 0.96449) 88.47%, rgba(244, 249, 255, 0.991353) 95.28%, #F4F9FF 102.08%);
      }

      &.selected {
        color: #333333;
      }

      &.notSelected {
        color: #828282;
      }

      &.logo {
        padding-left: 44px;
      }
    }

    &_arrowIcon {
      display: flex;
      width: 20px;
      justify-content: flex-end;
      align-items: center;
      transition-duration: 300ms;
      transform: rotateX(0);

      svg {
        width: 20px;
        height: 20px;
      }

      &.opened {
        transition-duration: 300ms;
        transform: rotateX(180deg);
      }
    }

    &_content {
      z-index: 2222;
      position: absolute;
      width: 100%;
      max-height: 300px;
      overflow-x: auto;
      top: 52px;
      left: 0;
      padding: 6px 2px 6px 2px;
      display: flex;
      flex-direction: column;
      background: #F4F9FF;
      box-shadow: 0 6px 11px rgba(125, 123, 123, 0.04);
      border-radius: 4px;

      &__item {
        display: flex;
        justify-content: normal;
        align-items: center;
        width: 100%;
        cursor: pointer;
        padding: 8px 10px 10px 10px;
        border-radius: 6px;
        overflow-wrap: anywhere;

        .GlobalButton {
          padding: 6px 12px 6px 8px;

          svg path {
            fill: #FE6D04;
          }
        }

        &:hover {
          background: #FFE1CC;
        }

        &_trashIcon {
          display: flex;
          align-self: flex-end;
          cursor: pointer;
          width: 16px;
          height: 16px;

          path {
            fill: #F54242;
          }
        }

        &_logo {
          margin-right: 10px;
        }
      }
    }

    &_logo {
      position: absolute;
      left: 4px;
      top: 4px;
    }
  }
}

