.inputContainer {
  display: flex;
  width: fit-content;
  flex-direction: column;
  margin-bottom: 24px;

  &__wrapper {
    position: relative;
    width: fit-content;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    color: #333333;
    margin-bottom: 12px;

    &.error{
      color: #F54242;
    }

    &.diabled {
      opacity: 0.5;
    }
  }

  &__input {
    height: 56px;
    background: #F4F9FF;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    outline: none;
    border: none;

    padding-left: 16px;
    padding-right: 12px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #333333;

    &.error {
      border: 3px solid #F54242;
    }

    &.noText {
      padding-right: 34px;
    }
  }

  &__input:disabled {
    opacity: 0.5;
  }

  &__passwordIcon {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 18px;
    width: 20px;
    height: 20px;
  }

  &__percentIcon {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 12px;
    width: 20px;
    height: 20px;
  }

  &__errorMessage {
    margin-top: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #F54242;
  }
}

