@import 'src/mixin.scss';

.bills__title {
  margin-top: 40px;
  @include desktop {
    margin-bottom: 16px;
  }
  @include mobile {
    margin-bottom: 8px;
  }
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  letter-spacing: -0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;

  color: #333333;
}

@include desktop {
  .bills__list {
    display: grid;

    &_header {
      display: grid;
      grid-template-columns: 30% minmax(20%, 1fr) 50%;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #333333;
      padding-bottom: 12px;
      border-bottom: 2px #E6E6EA solid;
      border-radius: 1px;

      th {
        display: flex;

        &:nth-child(2) {
          justify-content: flex-end;
          margin-right: 16px;
        }
      }
    }

    &_item {
      display: grid;
      grid-template-columns: 30% minmax(20%, 1fr) 50%;
      padding-top: 20px;
      padding-bottom: 16px;
      border-bottom: 1px #E6E6EA solid;
      border-radius: 1px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
      color: #333333;

      td:nth-child(2) {
        display: flex;
        justify-content: flex-end;
        margin-right: 16px;
      }
    }

    &_item > td:last-child {
      display: flex;
      justify-content: space-between;
    }
  }
}
