.telegramEdit {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 32px 48px 40px 48px;
  border-radius: 0 0 0 32px;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.20);

  & > p {
    margin-bottom: 12px;
  }

  & .inputContainer {
    margin-top: 20px;
  }

  & .inputContainer__input {
    width: 600px;
  }

  &__footer {
    margin-top: 40px;

    & > .GlobalButton {
      padding: 14px 16px;
    }
  }

  &__footer.clicked {
    pointer-events: none;
  }
}
