.permissionsList {
  display: flex;
  flex-direction: column;

  & > h4 {
    margin-bottom: 12px;
  }

  &__list{
    display: flex;
    flex-direction: column;
  }

  &__list > * + * {
    margin-top: 16px;
  }
}
