.phoneInput {

  & > p { 
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .form-control {
    border: none;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    background: #F4F9FF;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }

  .flag-dropdown, .selected-flag {
    border-radius: 6px 0 0 6px;
  }

  & .react-tel-input .invalid-number-message {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #F54242;
    margin-top: 12px;
    left: 0;
    top: 100%;
  }
}

.phoneField {
  display: flex;
  flex-direction: column;

  & > p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .errorMessage {
    margin-top: 0;
    font-size: 14px;
    color: #F54242;
  }

  .PhoneInput--disabled {
    opacity: 0.5;
  }

  .PhoneInput--readOnly {
    & .PhoneInputCountrySelector {
      pointer-events: none;
    }
  }

  .PhoneInput {
    & > input {
      height: 100%;
      outline: none;
      border: none;
      padding: 0 8px;
      background: #F4F9FF;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
      border-radius: 0 6px 6px 0;
    }
  }

  & > .error.PhoneInput {
    & > input {
      border-top: 3px solid #F54242;
      border-right: 3px solid #F54242;
      border-bottom: 3px solid #F54242;
      border-left: none;
    }
    & .PhoneInputCountrySelector {
      border-top: 3px solid #F54242;
      border-left: 3px solid #F54242;
      border-bottom: 3px solid #F54242;
      border-right: none;
    }
  }
}
