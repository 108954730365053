.editName {
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;

  .GlobalButton {
    padding: 6px 12px;

    svg path {
      fill: #FE6D04;
    }
  }

  & > .inputContainer {
    width: 100%;
    margin-bottom: 0;

    & > .inputContainer__wrapper {
      width: 100%;

      & input {
        width: 100%;
      }
    }
  }

  & > h1 {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    width: fit-content;
    max-width: 100%;
  }
}

.editName > * + * {
  margin-left: 16px;
}
