.tagsList {
  display: flex;
  overflow: hidden;

  & > * {
    width: 100%;
  }

  &__header {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(20%, 196px) auto;
    column-gap: 30px;
    border-bottom: 2px solid #E6E6EA;
    border-radius: 2px;
    padding-bottom: 12px;
    margin-right: 48px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: calc(100vh - 178px);
    padding-right: 48px;
  }

  &_item {
    display: flex;
    justify-content: space-between;

    & > .GlobalButton {
      padding: 6px 8px;

      svg path {
        fill: #FE6D04;
      }
    }
  }

  &_name {
    display: flex;
    align-items: center;
    height: 28px;
    min-width: fit-content;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 4px 8px;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.4);
    border-radius: 6px;

    & > p {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #333333;
    }
  }

  &_description {
    display: flex;
    justify-content: space-between;
    width: 432px;

    & > p {
      white-space: pre-wrap;
      overflow-wrap: break-word;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'cv05' on, 'cv09' on, 'cv02' on, 'cv01' on;
      color: #333333;
    }

  }
}
