.whatsAppPrompt {
  display: inline-flex;
  margin-left: 7px;
  position: relative;

  &_icon {
    width: 16px;
    height: 16px;
    align-self: center;

    path {
      fill: #FE6D04;
    }
  }

  &_message {
    display: none;
    position: absolute;
    top: -180px;
    left: -180px;
    padding: 20px 48px 18px 24px;
    width: 400px;
    background: #FFFFFF;
    box-shadow: 0 12px 48px -16px rgba(0, 0, 0, 0.65);
    border-radius: 12px;
    white-space: preline;
  }

  &:hover &_message {
    display: block;
  }

  &_wa_register path {
    fill: #2CBA5F;
  }

  &_wa_no_register path {
    fill: #F54242;
  }

  &_wa_no_verify path {
    fill: #D1D1D1;
  }

  &_item {
    display: flex;
    align-items: center;
    margin-top: 6px;

    svg {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }
  }
}
