@import 'src/mixin.scss';

.subscriptionsInvoicePay {

  &__payment {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    &_method {
      display: inline-flex;
    }
  }

  &__payment > div + div {
    margin-top: 12px;
  }

  & .radioControl {
    margin-bottom: 0;
  }
}
