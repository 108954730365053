.tagsPlug {

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  h1 {
    margin-bottom: 8px;
  }

  &__dude {
    width: 400px;
    height: 400px;
  }

  & > button {
    padding: 13px 16px 15px 12px;
    margin-top: 56px;

    svg path {
      fill: #FFFFFF;
    }
  }
}
