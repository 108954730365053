.mobileModal {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 20;

  & > :first-child {
    z-index: 21;
  }
  
  &__wrapper {
    width: 100%;
    height: 100%;
    cursor: default;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;

    &.none {
      background-color: rgba(0, 0, 0, 0);
    }
  
    &.light {
      background: #333333;
      opacity: 0.3;
    }
  
    &.dark {
      background: black;
    }
  }
}
