@import 'src/mixin.scss';

.wabaImageProfile {
  display: flex;
  margin-top: 12px;
  margin-bottom: 40px;
  width: 464px;
  height: 192px;
  padding: 20px 279px 20px 20px;
  align-items: center;
  border-radius: 8px;
  background: #F9F9F9;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06) inset;

  @include mobile {
    margin: 0;

    svg {
      width: 16px;
      height: 16px;
      margin: 0;
    }
  }

  &__image {
    display: flex;

    @include mobile {
      margin: 0;
    }

    & > img {
      width: 165px;
      height: 152px;
      object-fit: cover;
      position: relative;
    }
  }

  &__trashIcon {
    position: absolute; 
    top: 22px; 
    cursor: pointer;
    border-radius: 50%;
    background: var(--Background-White, #FFF);
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.20);
    width: 24px;
    height: 24px;

    path {
      fill: #F54242;
    }
  }
}