.notificationBanner {
  display: flex;
  max-width: 752px;
  min-height: 184px;
  margin-top: 24px;
  margin-bottom: 24px;
  border-radius: 12px;
  border: 1px solid #E0E0E0;

  & > :not(:first-child) {
    border-left: 1px solid #E0E0E0;
  }
}
