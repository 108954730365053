.odnoklassnikiEdit{
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 32px 48px 40px 48px;
  border-radius: 0 0 0 32px;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.20);

  & > p {
    margin-bottom: 12px;
  }

  & > a {
    width: fit-content;
    margin-bottom: 12px;
    text-decoration: none;
    color: #FE6D04;

    &:hover {
      text-decoration: underline;
    }
  }

  & > button {
    margin-top: 40px;
    padding: 14px 16px 15px;
  }

  .inputContainer__input {
    width: 400px;
  }
}
