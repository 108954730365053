.companyDropdown {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  background: #FFFFFF;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  width: 176px;

  &.rolledUp {
    width: 44px !important;
    justify-content: center !important;
    margin-left: -10px;

    & .popupWrapper.top {
      left: 0;
    }

    &::after {
      content: none;
    }
  }

  &__logo {
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.mobile {
    width: auto;
    justify-content: space-between;
    margin-top: 24px;

    .companyDropdown__icon {
      margin-right: 10px;
    }

    & .popupWrapper.bottom {
      top: 48px;
    }
  }

  & .popupWrapper.top {
    width: 236px;
  }

  &__icon {
    display: flex;
    z-index: 2;
    transition: transform 0.2s;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__name {
    height: 20px;
    width: 150px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 12px;
    margin-right: 4px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 32px;
    height: 30px;
    right: 19px;
    background: linear-gradient(265deg, white 0%, rgb(255, 255, 255) 14.5%, rgb(255, 255, 255) 54%, rgba(255, 255, 255, 0.37) 96%, rgba(255, 255, 255, 0.38) 100%)
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 220px;
    max-height: 184px;
    margin: 8px 6px 12px 6px;
    overflow-y: auto;

    &_item {
      cursor: pointer;
      width: 204px;
      min-height: 36px;
      display: flex;
      flex-direction: column;
      background: #F5F4F3;
      border: 1px solid #F2F2F2;
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 8px;
      padding-left: 12px;
      justify-content: center;

      &.active {
        position: relative;
        background: #EFF9F3;
        border: 1px solid #80D89F;

        &:after {
          content: url("../../../../../assets/checkmark-icon.svg");
          width: 12px;
          height: 8px;
          position: absolute;
          top: 6px;
          right: 8px;
        }
      }

      & > p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        color: #333333;
        max-width: 155px;
        position: relative;
        overflow: hidden;
        white-space: nowrap;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          width: 17px;
          height: 30px;
          right: -1px;
          background: linear-gradient(90deg, rgba(245, 244, 243, 0) 0%, rgba(245, 244, 243, 0.0086472) 6.67%, rgba(245, 244, 243, 0.03551) 13.33%, rgba(245, 244, 243, 0.0816599) 20%, rgba(245, 244, 243, 0.147411) 26.67%, rgba(245, 244, 243, 0.231775) 33.33%, rgba(245, 244, 243, 0.331884) 40%, rgba(245, 244, 243, 0.442691) 46.67%, rgba(245, 244, 243, 0.557309) 53.33%, rgba(245, 244, 243, 0.668116) 60%, rgba(245, 244, 243, 0.768225) 66.67%, rgba(245, 244, 243, 0.852589) 73.33%, rgba(245, 244, 243, 0.91834) 80%, rgba(245, 244, 243, 0.96449) 86.67%, rgba(245, 244, 243, 0.991353) 93.33%, #F5F4F3 100%)
        }
      }
    }
  }
}
