.wabaCatalogs {
  display: flex;
  flex-direction: column;
  height: 100%;

  h1 {
    margin-bottom: 24px;
  }

  a {
    text-decoration: none;
    color: #FE6D04;

    &:hover {
      text-decoration: underline;
    }
  }

  & > ol:first-of-type {
    margin-top: 12px;
  }
  
  & > ol > li + li {
    margin-top: 8px;
  }
  
  & > ol:last-of-type {
    counter-reset: steps 1;
    margin-top: 8px;
    margin-bottom: 24px;
  }

  & span {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.07px;

    color: #828282;
  }

  & span:first-of-type {
    margin-bottom: 80px;
  }

  & .inputContainer {
    width: 100%;
    
    &__wrapper {
      width: 100%;

      &> input {
        width: 100%;
      }
    }
  }

  & .inputContainer:first-of-type {
    margin-top: 48px;
  }

  & .warn {
    margin-top: auto;
  }

  & > button {
    margin: 24px 0 20px;
    color: white;
    cursor: pointer;

    width: fit-content;
    min-height: 36px;
    padding: 6px 8px 6px 12px;

    outline: none;
    border: none;

    border-radius: 8px;
    background: #0E6EEB;
    box-shadow: 0px 4px 4px -2px rgba(0, 65, 141, 0.20);

    transition-duration: 200ms;

    &:active:not(:disabled) {
      transition-duration: 200ms;
      transform: translate(0em, 0.15em);
    }
  }

  & > button:disabled {
    background-color: #828282;
    border: 1px solid #E0E0E0;
  }
}
