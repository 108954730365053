.wabaStart {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  .loaderContainer {
    width: 100%;
    height: 100%;
  }

  & > .GlobalButton {
    padding: 13px 16px 15px 16px;
  }

  &__content {
    display: flex;
  }
}
