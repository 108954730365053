.popup {
  position: relative;

  &:hover {

    .popupTitle {
      display: inline-block;
      animation: fadein 400ms ease-in;
    }
  }
}

  .popupTitle {
    display: none;
    animation: fadein 400ms ease-in;
    position: absolute;
    top: -14px;
    left: 40px;
    text-decoration: none;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0 100px 80px rgba(0, 0, 0, 0.12), 0 41.7776px 33.4221px rgba(0, 0, 0, 0.0862625), 0 22.3363px 17.869px rgba(0, 0, 0, 0.0715329), 0 12.5216px 10.0172px rgba(0, 0, 0, 0.06), 0 6.6501px 5.32008px rgba(0, 0, 0, 0.0484671), 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0337375);
    border-radius: 12px;
    padding: 12px 16px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    color: #333333;
    z-index: 11;

    &::after {
      content: url('../../../../../assets/left-corner.svg');
      width: 8px;
      height: 8px;
      position: absolute;
      left: -6px;
      top: 13px;
    }
  }
