.notificationConnect {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 24px 8px 8px 8px;

  &_logo {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 28px;

    & > svg {
      width: 72px;
      height: 72px;
    }
  }

  &_create {
    display: inline-flex;
    padding: 8px 8px 8px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: #F4F9FF;

    & > p {
      text-align: center;
    }

    & > button {
      padding: 6px 12px;
    }
  }
}
