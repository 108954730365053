.introduction {
  display: flex;
  flex-direction: column;

  & > h3 {
    margin-bottom: 12px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    & > .GlobalButton {
      padding: 8px 12px;
    }
  }
}
