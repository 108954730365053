.switcher {
  display: inline-flex;
  align-items: center;

  &__wrapper {
    display: flex;
    flex-direction: column;

    & > p {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 12px;
    }
  }

  &__wrapper[disabled] > * {
    pointer-events: none;
    color: #828282;
  }

  &__wrapper[disabled] {
    .switch-btn::after {
      opacity: 0.6;
    }
  }
}

.switch-btn {
  display: inline-block;
  width: 40px;
  height: 25px;
  margin: 7px;
  border-radius: 12px;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  background: linear-gradient(0deg,#e6e6ea,#e6e6ea);
  z-index: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  position: relative;
  transition-duration: 300ms;
}

.switch-btn::after {
  content: "";
  height: 24px;
  width: 24px;
  top: 0;
  left: 0;
  transition-duration: 300ms;
  background: #FFFFFF;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 18px;
  position: absolute;
  z-index: 1;
}

.switch-on {
  background: linear-gradient(0deg, #E6E6EA, #E6E6EA);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.switch-on::after {
  left: 40%;
  background: #2CBA5F;
  box-shadow: 0 2px 4px -2px rgba(24, 123, 59, 0.65);
  border-radius: 18px;
}
