.createAndEditTag {
  position: absolute;
  z-index: 1;
  width: 24vw;
  right: 30px;
  top: 30px;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 12px;
  padding: 24px 20px 16px 24px;

  .loaderContainer {
    height: 460px;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    svg {
      position: absolute;
      cursor: pointer;
      top: 16px;
      right: 16px;
      width: 20px;
      height: 20px;
    }
  }

  .textareaContainer {
    margin-bottom: 16px;

    textarea {
      width: 100%;
      height: 60px;
      padding: 8px 12px;
    }
  }

  .inputContainer {
    width: 100%;
    margin-bottom: 16px;

    &__wrapper {
      width: 100%;
    }

    &__input {
      width: 100%;
      height: 36px;
      padding: 8px 12px;
    }
  }

  textarea, input {
    color: #333333;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.07px;
  }

  .inputContainer__label, .textareaContainer_header {
    margin-bottom: 4px;

    & > * {
      color: #333333;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.07px;

      &:last-child {
        font-weight: 400;
        letter-spacing: -0.56px;
      }
    }
  }

  &__colors {

    p {
      margin-bottom: 8px;
    }

    .colorItems {
      display: flex;
      flex-flow: wrap row;

      &_item {
        position: relative;
        border-radius: 28px;
        width: 32px;
        height: 32px;
        margin-right: 12px;
        margin-bottom: 12px;

        .dawIcon {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &__footer {
    margin-bottom: 32px;

    p {
      margin-bottom: 8px;
    }

    &_preview {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.4);
      border-radius: 6px;
      padding: 4px 8px;
      width: fit-content;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;

    &_save {
      padding: 6px 12px;
    }

    &_close {
      padding: 6px 12px;
    }

    .GlobalButton {
      padding: 6px 12px;
    }
  }
}
