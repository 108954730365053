.introductionStep {
  display: flex;
  flex-direction: column;

  & > h3 {
    margin-bottom: 12px;
  }

  & > a {
    text-decoration: none;
    color: #FE6D04;
    margin-top: 8px;
  }

  &__stepper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 26px;

    &_steps {
      display: flex;

      & > .scale {
        width: 129px;
        height: 24px;
        align-items: center;
        justify-content: space-evenly;
        background-color: #EBEBEB;
        border-radius: 12px;
        display: flex;
        margin-right: 8px;
  
        & > .step {
          transition: background-color, 800ms ease-in-out;
          background-color: #FFFFFF;
          cursor: pointer;
          width: 8px;
          height: 8px;
          border-radius: 5px;
          
          &.active {
            background-color: #FE6D04;
          }
        }
      }
    }
    
    &_nav {
      display: flex;
      align-items: center;
      
      .GlobalButton {
        padding: 6px 8px 6px 8px;
      }
      
      #next svg {
        transform: rotateY(180deg);
      }
      
      #prev {
        margin-right: 12px;
      }
    }
  }
}