@import 'src/styles.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Inter, sans-serif;
  color: #333333;
  box-sizing: border-box;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.emoji-mart {
  font-family: Inter, sans-serif!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track-piece:end {
  margin-bottom: 15px;
}

::-webkit-scrollbar-thumb {
  height: 40px;
  background: #BDBDBD;
  border-radius: 15px;
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

@keyframes ani {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes vslide {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
