.integrationItem {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 240px;

  & > *:first-child {
    margin-bottom: 12px;
  }

  & > p {
    color: #474747;
    text-align: center;
    word-break: break-word;
  }

  & > img {
    width: 240px;
    height: 130px;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 12px;
  }

  &.disabled {
    & > img {
      filter: grayscale(100%);
    }
  }
}
