.menuButtons {

  .close {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .minusIcon {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
  }
}
