.wabaStartPreview {

  h3 {
    margin-bottom: 10px;
  }

  &__wrapper {
    width: 288px;
    min-height: 56px;
    border-radius: 12px;
    border: 1px solid #e0e0e0;
    padding: 12px;
    background-image: url('../../../../../../../../assets/whanstapp-background.png');
    background-size: contain;
  }

  &__content {
    padding: 10px 16px;
    border-radius: 14px;
    background: #FFF;

    &_item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      p {
        overflow-wrap: anywhere;
      }

      svg {
        width: 20px;
        min-width: 20px;
        height: 20px;
        min-height: 20px;

        path {
          fill: #25D366;
        }
      }
    }

    &_footer {
      display: flex;
      justify-content: space-between;
      height: 24px;

      & > div {
        display: flex;
      }

      .left svg {
        margin-right: 8px;
      }

      .right svg:first-child {
        margin-right: 12px;
      }

      p {
        color: #828282;
      }

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: #D1D1D1;
        }
      }
    }
  }
}
