@import 'src/mixin.scss';

.subRequisitesList {

  &__header {
    display: inline-flex;
    width: 100%;
    padding-right: 48px;
    padding-bottom: 12px;
    border-bottom: 2px #E6E6EA solid;
    border-radius: 1px;
    
    & > div + div {
      margin-left: 16px;
    }

    & > div:nth-child(1) {
      flex-basis: 264px;
    }
      
    & > div:nth-child(2) {
      flex-basis: 148px;
    }

    & > div:last-child {
      flex-grow: 1;
    }

    .smallText {
      text-align: start;
    }

    @include mobile {
      display: none;
    }
  }

  &__content {
    @include desktop {
      overflow-y: auto;
      height: calc(100vh - 248px);
      padding-right: 48px;
    }

    @include mobile {
      & > div + div {
        margin-top: 12px;
      }
    }
  }
}
