.notificationPanelTelegram {
  display: flex;
  flex-direction: column;

  & > p {
    margin-top: 16px;
  }

  & > ol {
    color: #333333;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.09px;

    & > li + li {
      margin-top: 8px;
    }
  }

  & > img {
    width: fit-content;
    height: fit-content;
  }

  &__link {
    position: relative;
    margin-top: 16px;

    & > input {
      cursor: pointer;
      display: flex;
      outline: none;
      border: none;
      padding: 8px 40px 8px 12px;
      width: 100%;
      height: 36px;
      border-radius: 8px;
      background: #EDF4FF;

      &:hover {
        color: #FA822C;
      }
    }

    svg {
      position: absolute;
      cursor: pointer;
      right: 12px;
      top: 9px;
      width: 16px;
      height: 16px;

      path {
        fill: #FA822C;
      }
    }
  }
}

