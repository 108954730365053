.switch {
  margin-bottom: 24px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 3px;
  background: #F9F9F9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;

  &__item {
    cursor: pointer;
    padding: 7px 12px;
    width: fit-content;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    background: #F9F9F9;
    text-align: center;
    color: #4F4F4F;

    &.active {
      color: #FFFFFF;
      background: #FE6D04;
      box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.15);
      border-radius: 7px;
    }
  }
}
