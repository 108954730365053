.videoPlayer {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;

  video {
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .video-controls {
    position: absolute;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      width: fit-content;
      height: fit-content;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      cursor: pointer;
      z-index: 1;
    }

    .pauseBackground {
      position: absolute;
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0.6;
      z-index: 1;
    }
  }
}
