.emojiComponent {
  position: relative;
  width: fit-content;
  display: flex;

  .emojiIcon {
    cursor: pointer;
    width: 20px;
    height: 20px;

    path {
      fill: #828282;
    }
  }
}
