.lifespanInput {
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    margin-bottom: 12px;
  }

  &__input {
    width: 100%;
    height: 56px;
    outline: none;
    border: none;
    padding: 16px;
    border-radius: 6px;
    background: #F4F9FF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.20) inset;
    color: #333;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.09px;
  }
}
