.progressSteps {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-bottom: 16px;

  &:before {
    content: '';
    height: 1px;
    top: calc(50% - 1px);
    position: absolute;
    width: 100%;
    background: #EBEBEB;
  }

  .stepContainer {
    background: white;
    position: relative;
    padding-left: 8px;
    padding-right: 8px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .step {
    background: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border: 1px solid #EBEBEB;
    color: #D1D1D1;
    border-radius: 12px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.005em;

    &.success {
      border: none;
      color: transparent;
      background: #2CBA5F;

      svg {
        position: absolute;
        width: 16px;
        height: 16px;

        path {
          fill: #FFFFFF;
        }
      }
    }

    &.active {
      color: #2CBA5F;
      border: 1px solid #2CBA5F;
    }
  }
}
