.tags {
  width: 100%;
  height: 100%;
  padding: 32px 0 40px 48px;
  border-radius: 32px 0 0 32px;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.20);

  .loaderContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    padding-right: 48px;

    & > button {
      padding: 13px 16px 15px 12px;

      svg path {
        fill: #FFFFFF;
      }
    }
  }
}
