.errorContainer{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  &__left{

    h1{
      width: 460px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 56px;
      line-height: 72px;
      color: #333333;
      margin-bottom: 80px;
      letter-spacing: -0.01em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'ordn' on, 'cv06' on, 'cv07' on, 'cv08' on;
    }
    &_text{
      display: flex;

      p{
        cursor: pointer;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #333333;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      }
      img{
        cursor: pointer;
        margin-right: 12px;
      }
    }
  }
}
