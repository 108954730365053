.wabaProfile {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  & .wabaTextarea, .dropdownContainer {
    width: 464px;
  }

  & .dropdownContainer {
    margin-top: 12px;
    margin-bottom: 0px;

    &__dropdown {
      width: 464px;
    }
  }

    &__about {
        position: relative;
        width: 464px;

    & .emojiComponent {
      position: absolute;
      top: 75px;
      right: 10px;
      z-index: 2;
    }
  }

  & .inputContainer {

    &__input {
      width: 464px;
      padding-right: 32px;
    }
  }

    &__pictures_preview {
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 464px;
        height: 192px;
        padding: 32px 48px 48px 32px;
        margin-top: 12px;
        margin-bottom: 40px;
        border-radius: 8px;
        background: #F9F9F9;
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06) inset;

    & > svg {
      width: 48px;
      height: 48px;
      margin-bottom: 20px;
    }

    & > p {
      margin-bottom: 4px;
    }

    &.error {
      border: 1px solid #F54224;
    }
  }

  &__save { 
    & .GlobalButton {
      padding: 13px 16px 15px 16px;
    }
  }

  & > .loaderContainer {
    width: auto;
  }
}
