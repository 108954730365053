.notificationCard {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 400px;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #E0E0E0;

  #delete {
    position: absolute;
    top: 26px;
    right: 24px;
    border: 1px solid #FFB3B3;
    padding: 6px 8px;

    svg path {
      fill: #EB5757;
    }
  }

  &_phone {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    svg {
      width: 32px;
      height: 32px;
      margin-right: 4px;
    }

    .whatsappIcon path {
      fill: #25D366;
    }

    p {
      color: #000000;
    }
  }

  & .GlobalButton[type=submit] {
    margin-top: 15px;
    padding: 15px 16px;
  }
}
