.switchControl {
  display: flex;
  border: none;
  align-items: center;
  
  label {
    display: flex;
    align-items: center;
    width: fit-content;

    input[type=checkbox] {
      all: revert;
      height: 0;
      width: 0;
      opacity: 0;
      position: absolute;
    }
  
    input[type=radio] {
      all: revert;
      height: 0;
      width: 0;
      opacity: 0;
      position: absolute;
    }
    
    #checkmark {
      cursor: pointer;
      margin: 7px;
      min-width: 40px;
      height: 25px;
      box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
      background: linear-gradient(0deg,#e6e6ea,#e6e6ea);
      border-radius: 40px;
      position: relative;
    }
    
    #checkmark:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background: #FFFFFF;
      box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
      border-radius: 18px;
      transition-duration: 0.3s;
    }
    
    input:checked + #checkmark {
      background: linear-gradient(0deg,#e6e6ea,#e6e6ea);
    }
    
    input:checked + #checkmark:after {
      left: 40%;
      background: #2CBA5F;
      box-shadow: 0 2px 4px -2px rgba(24, 123, 59, 0.65);
      border-radius: 18px;
    }
  
    input[type=checkbox]:disabled ~ span {
      color: #828282;
    }
  
    input[type=checkbox]:disabled + #checkmark:after {
      opacity: 0.6;
    }
  }

  & .info__block {
    position: relative;

    & > .info__message {
      display: none;
      position: absolute;
      width: 280px;
      height: fit-content;
      left: -196px;
      top: -82px;
      border-radius: 12px;
      padding: 16px 32px 16px 16px;
      white-space: pre-line;
      font-size: 14px;
      line-height: 20px;
      z-index: 10;
    }
  
    & >.info__icon:hover ~.info__message {
      display: block;
    }

    & > .info__icon {
      align-items: center;

      & svg {
        width: 16px;
        height: 16px;
        margin-left: 12px;

        path {
          fill: #FE6D04;
        }
      }
    }
  }
}
