.onboardingButton {
  width: fit-content;
  padding: 8px 12px;
  border-radius: 0 8px 8px 0;
  background: #FE6D04;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.20);
  margin-bottom: 24px;
  cursor: pointer;

  &.red {
    background: #F54242;
  }

  p {
    color: #FFFFFF;
  }

  & > svg path {
    fill: #FFFFFF;
  };
}
