.optionSelect {
  display: flex;

  & > .select > fieldset {
    input {
      width: 220px;
    }
  }
}

.optionSelect > * + * {
  margin-left: 4px;
}
