@import 'src/mixin.scss';

.subscriptionSlot {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 12px 20px;
  border-radius: 8px;
  background: #EAF3FF;
  margin-top: 8px;
  margin-bottom: 8px;

  & > h4 {
    margin-bottom: 12px;
  }

  & > svg {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
    width: 16px;
    height: 16px;

    path {
      fill: #FE6D04;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
    }
  }

  &__counter {

    & > .GlobalButton {
      padding: 6px 12px;
      margin-top: 12px;
    }

    @include mobile {
      margin-bottom: 24px;

      .smallText { grid-area: f; }
      .subscriptionSlot__slots { grid-area: i; }
      .GlobalButton { grid-area: b; }

      display: grid;
      grid-template-areas: "f f f f f f f f"
                         "f f f f f f f f"
                         "i i i i i b b b"
                         "i i i i i b b b"
                         "i i i i i b b b";
    }
  }

  &__slots {
    display: flex;
    margin-top: 12px;

    & > .GlobalButton {
      width: 32px;
      height: 32px;
      padding: 8px;
    }

    & > input {
      width: 48px;
      text-align: center;
      margin: 0 8px;
      outline: none;
      border: none;
      background: transparent;
    }

    & > input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    & > input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &__discounts {
    width: fit-content;
    height: fit-content;
    padding: 8px 12px;
    border-radius: 8px;
    background: #FFE1CC;
    display: flex;
    color: #333333;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: -0.05px;

    .discount {
      margin-right: 8px;
      font-weight: 600;
    }
  }

  &__warning {
    margin-top: 12px;
    color: #F54242;
  }
}
