@import 'src/mixin.scss';

.bills__list {

  .expired {
    color: #F54242 !important;
  }

  .confirmed div:last-child {
    color: #2cba5f !important;
  }

  @include mobile {
    .confirmed {
      color: #2cba5f !important;
      position: relative;

      .downloadFile {
        position: absolute;
        right: 0;
        bottom: 28px;
        font-size: 14px;
        font-family: Inter, sans-serif;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.07px;

        svg {
          width: 16px;
          height: 16px;
          margin-right: 8px;

          path {
            fill: #FE6D04;
          }
        }
      }
    }
  }

  &_item {
    & .GlobalButton {
      padding: 6px 12px;
      @include mobile {
        margin-top: 8px;
      }
    }

    .downloadFile {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: right;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #FE6D04;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;

        path {
          fill: #FE6D04;
        }
      }
    }
  }
}
