@import 'src/mixin.scss';

.subscriptionPlan {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 12px 20px;
  border-radius: 8px;
  background: #EAF3FF;
  margin-top: 8px;

  & > h4 {
    margin-bottom: 12px;
  }

  & > svg {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
    width: 16px;
    height: 16px;

    path {
      fill: #FE6D04;
    }
  }

  &__tariffs {
    & > .radioControl + .radioControl {
      margin-top: 8px;
    }

    & > .radioControl {
      margin-bottom: 0;

      & > label {
        & > * + * {
          margin-right: 0;
        }

        & > span + span {
          margin-left: 8px;
        }

        & > span {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  & > .GlobalButton {
    padding: 6px 12px;
    margin-top: 12px;
  }

  &__notes {
    margin-top: 12px;

    & > p + p {
      margin-top: 4px;
    }

    & > p {
      color: #333;
      font-variant-numeric: lining-nums proportional-nums stacked-fractions;
      font-feature-settings: 'cpsp' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
      font-family: Inter, sans-serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; /* 120% */
      letter-spacing: -0.05px;
    }
  }
}
