@import 'src/mixin.scss';

.roleSelector {
  height: 80px;
  position: relative;
  
  & .select {
    width: 100%;
    margin-top: 16px;
  
    & > fieldset {

      & > input {
        width: 100%;
        height: 36px;
        font-size: 16px;
        color: #333333;
      }

      & > ul {
        position: fixed;
        top: auto;
        left: auto;
        width: 460px;

        @include mobile {
          width: calc(100% - 64px);
          height: 90px;
        }
      }
    }

    & > label {
      margin-bottom: 4px;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.07px;
    }
  }
}
