@import 'src/mixin.scss';

.dialogsInput {
  display: flex;
  flex-direction: column;

  & > h3 {
    @include desktop {
      margin-bottom: 8px;
    }
    @include mobile {
      margin-bottom: 12px;
    }
  }

  & > p {
    margin-bottom: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    @include desktop {
      font-size: 14px;
      line-height: 20px;
    }
    @include mobile {
      font-size: 18px;
      line-height: 24px;
    }
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    color: #333333;
  }

  & .inputContainer {
    width: 100%;

    &__wrapper {
      width: 100%;

      & input {
        width: 100%;
        padding: 16px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'cv05' on, 'cv09' on, 'cv02' on, 'cv01' on;
        color: #333333;
      }
    }
  }
}
