.roleCreate {
  display: flex;
  flex-direction: column;
  width: 384px;
  min-height: 190px;
  height: fit-content;
  padding: 20px 24px 24px 24px;
  background: #FFFFFF;
  border-radius: 12px;
  background: var(--Background-White, #FFF);
  box-shadow: 0px 12px 48px -16px rgba(0, 0, 0, 0.65);

  & > .inputContainer {
    margin-bottom: 16px;
    width: 100%;

    & > .inputContainer__label {
      margin-bottom: 4px;

      & > p {
        font-size: 14px;
      }
    }

    & > .inputContainer__wrapper {
      width: 100%;

      & .inputContainer__input {
        width: 100%;
        height: 36px;
      }
    }

    & > .inputContainer__errorMessage {
      margin-top: 4px;
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;

    & > svg {
      width: 16px;
      height: 16px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;

    & > .GlobalButton {
      padding: 6px 12px;
    }
  }
}
