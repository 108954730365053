.finishStep {

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .GlobalButton {
      padding: 6px 12px;
    }
  }
}
