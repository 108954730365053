.bePaidEditContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 32px 48px 40px 48px;
  border-radius: 0 0 0 32px;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.20);

  & > button {
    margin-top: 40px;
    padding: 14px 16px 15px;
  }

  &_input {
    margin-top: 24px;

    input {
      width: 400px;
    }
  }
}

