.textareaContainer {
  margin-bottom: 24px;

  &_header{
    display: flex;
    justify-content: space-between;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'cpsp' on;
    color: #333333;
    margin-bottom: 12px;
  }

  &_textarea {
    background: #F4F9FF;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    border: none;
    resize: none;
    outline: none;
    padding: 12px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'cv08' on, 'case' on;
    color: #333333;
  }

  &_errorMessage {
    margin-top: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #F54242;
  }
}
