.companyLogo {

  &__logo {
    height: 24px;
  }

  &__id {
    margin-right: 14px;
    padding: 4px 6px;
    border-radius: 6px;
    background: #D6D6E9;
    height: fit-content;
    color: #4F4F4F;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.48px;
    font-variant-numeric: lining-nums tabular-nums stacked-fractions ordinal;
    font-feature-settings: 'cv08' on, 'cpsp' on, 'case' on, 'cv05' on, 'cv02' on, 'cv01' on, 'cv09' on;

    &.rolledUp {
      display: none;
    }
  }
}
