.optionList {
  margin-top: 40px;
  display: grid;

  &__title {
    display: grid;
    grid-template-columns: 100px auto 424px 50px;

    &.hide {
      grid-template-columns: auto 424px 50px;
    }

    column-gap: 16px;
    align-items: start;
    justify-items: start;
    padding-bottom: 12px;
    border-bottom: 2px #E6E6EA solid;

    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on, 'cv08' on;
  }
}
