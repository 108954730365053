@import 'src/mixin.scss';

.requisites{
  margin-top: 40px;
  margin-bottom: 24px;

  & > .dropdownContainer {
    @include desktop {
      width: 400px;
    }
    @include mobile {
      width: 100%;
    }
  }
}
