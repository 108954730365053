.introductionPopup {
  display: flex;
  flex-direction: column;
  position: absolute;
  min-width: 390px;
  width: fit-content;
  padding: 20px 24px;
  border: 1px solid #F2F2F2;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  transition: all 700ms ease;
  transition-property: top, left, bottom, right, height;

  & .closeIcon {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 24px;
    width: 20px;
    height: 20px;
  }

  & .tailIcon {
    width: 24px;
    height: 15px;
    cursor: pointer;
    position: absolute;
  }  
}
