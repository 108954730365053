.radioControl {
  display: flex;
  align-items: center;
  border: none;
  margin-bottom: 12px;
  position: relative;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.005em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
  color: #333333;
  
  label {
    display: flex;
    align-items: center;
    width: fit-content;

    .checkmark {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 18px;
      width: 18px;
      min-width: 18px;
      background: #FFFFFF;
      border: 2px solid #333333;;
      border-radius: 50%;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    input:checked ~ .checkmark:after {
      display: flex;
    }

    .checkmark:after {
      width: 8px;
      height: 8px;
      background: #2CBA5F;
      box-shadow: 0 2px 4px -2px rgba(24, 123, 59, 0.65);
      border-radius: 50%;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      width: 18px;
      height: 18px;
      box-shadow: none;
    }
  }

  label:has(input:disabled) {
    opacity: 0.5;
  }
  
  & .info__block {
    position: relative;

    & > .info__message {
      display: none;
      position: absolute;
      width: 320px;
      height: fit-content;
      left: -196px;
      top: -56px;
      border-radius: 12px;
      padding: 16px 32px 16px 16px;
      white-space: pre-line;
      font-size: 14px;
      line-height: 20px;
      z-index: 10;
    }

    & >.info__icon:hover ~.info__message {
      display: block;
    }

    & > .info__icon {
      display: inline-flex;
      align-items: center;

      & svg {
        width: 16px;
        height: 16px;

        path {
          fill: #FE6D04;
        }
      }
    }
  }
  label > * + * {
    margin-right: 12px;
  };
}
