.integrationsList {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 32px 0 40px 48px;
  border-radius: 32px 0 0 32px;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.20);

  &.guideOn {
    overflow-y: hidden;
  }

  & h1 {
    margin-bottom: 16px;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(auto-fill, 240px);
    grid-row-gap: 32px;
    grid-column-gap: 16px;
    padding-right: 48px;
  }

  &__items:first-of-type {
    margin-bottom: 40px;
  }
}
