.connectionItem {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;
  margin-bottom: 26px;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 128px;
    margin-bottom: 12px;
    border-radius: 10px;
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.25);
  }
}
