.wabaEdit {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding: 32px 48px 40px 48px;
  border-radius: 0 0 0 32px;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.20);

  & > p {
    margin-bottom: 12px;
  }

  & .switchTabs {
    margin-bottom: 24px;
  }

  & .inputContainer {
    margin-top: 20px;
  }

  & .inputContainer__input {
    width: 600px;
  }

  &__footer {
    margin-top: auto;

    & > button {
      padding: 15px 15px;
    }

    &.clicked {
      pointer-events: none;
    }
  }

  &__catalog {
    display: flex;
    margin-top: 32px;

    & span {
      font-weight: bold;
    }

    &.disconnected, &.expired {
      color: #F54242;

      & > svg path {
        fill: #F54242;
      }
    }

    &.connected {
      color: #0E6EEB;

      & > svg path {
        fill: #0E6EEB;
      }
    }
  }

  &__catalog > * + * {
    margin-left: 12px;
  }

  .switchControl .info__message {
    top: auto;
    left: auto;
    bottom: 30px;
  }
}
