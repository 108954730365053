@import "src/mixin.scss";

.userEdit {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > .permissionsList, .rolesList {
    margin-top: 24px;
  }

  &__close {
    position: absolute;
    cursor: pointer;
    top: 16px;
    right: 16px;

    svg {
      width: 24px;
      height: 24px;
    
      path {
        fill: #333333;
      }
    }
  }

  &__rolesButtons{
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;

    & > .GlobalButton:nth-child(1) {
      padding: 6px 12px;
      margin-right: 24px;
    }

    & > .GlobalButton:nth-child(2) {
      padding: 6px 12px 6px 8px;
      color: #F54242;
      border: 1px solid #FF5B5B;

      svg {
        path {
          fill: #F54242;
        }
      }

      &:hover {
        transition-duration: 300ms;
        color: #FF6262;
        border: 1px solid #FF8585;

        svg path {
          fill: #FF6262;
        }
      }

      &:active {
        transition-duration: 200ms;
        color: #FF7070;
        border: 1px solid #FFC3C3;

        svg path {
          fill: #FF7070;
        }
      }
    }
  }  
}
