.planItem {
  &_button {
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    color: #FE6D04;
    text-decoration: underline;
  }
}
