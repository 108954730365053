.notificationPanelWhatsapp {
  display: flex;
  flex-direction: column;
  height: 100%;

  & > p {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  form {
    display: flex;
    flex-direction: column;
    height: 100%;

    .phoneField {
      .PhoneInput {
        width: 320px;
        height: 56px;

        & > input {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }

  .GlobalButton {
    margin-top: auto;
    padding: 13px 16px 15px 16px;
  }
}
