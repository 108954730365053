.tabShadowShops {
  width: 192px;
  position: relative;
  padding: 8px;

  &.rolledUp {
    width: 44px;
    padding: 8px;

    .tabShop {
      justify-content: center;
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &.active {
    border-radius: 10px;
    background: linear-gradient(192deg, #E6E6EA 9.05%, #F5F4F3 91.82%);
    box-shadow: 0 2px 8px -2px rgba(63, 75, 138, 0.10);
    padding: 8px;

    &.rolledUp {
      margin-left: 0;
      border-radius: 12px 12px 16px 16px;
    }
  }

  &.outActive {
    background: linear-gradient(191.75deg, #E6E6EA 9.05%, #F5F4F3 91.82%);
    box-shadow: 0 2px 8px -2px rgba(63, 75, 138, 0.1);
    border-radius: 12px;
    padding: 8px;

    &.rolledUp {
      border-radius: 12px;
      padding: 8px;
    }

    & .tabShop p {
      color: #FE6D04;
    }

    & svg path {
      fill: #FE6D04;
    }
  }

  & .tabShop {
    cursor: pointer;
    display: flex;
    height: 24px;

    &.rolledUp {

      &:hover {
        p {
          color: #FE6D04;
        }

        svg path {
          fill: #FE6D04;
        }
      }
    }

    .tab__icon {
      position: relative;
      margin-right: 8px;

      &:hover {

        .popupTitle {
          display: inline-block;
          animation: fadein 400ms ease-in;
          position: fixed;
          top: auto;
          left: 64px;
        }
      }

      &.rolledUp {
        margin-right: 0;
        height: 24px;

        .salesIcon.outActive path {
          fill: #828282;
        }

        &:hover svg path {
          fill: #FE6D04 !important;
        }
      }


      &.rolledUp {
        svg {
          margin-right: 0;
        }
      }
    }
  }

  & .arrow {
    transform: rotateX(180deg);
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    transition-duration: 300ms;

    &.active {
      transition-duration: 300ms;
      transform: rotateX(0);
    }
  }

  & .tabList {
    margin-left: 32px;

    &.rolledUp {
      margin-left: 0;
      display: flex;
      flex-direction: column;

      .popup {
        display: flex;
        justify-content: center;
      }

      a {
        margin-top: 16px;
        position: relative;

        &:hover .popupTitle {
          display: inline-block;
          animation: fadein 400ms ease-in;
          position: fixed;
          top: auto;
          left: 64px;
        }
      }

      a:hover svg path {
        fill: #FE6D04;
      }
    }

    & .tabItem.active {
      color: #FE6D04;

      path {
        fill: #FE6D04;
      }
    }

    & > a > p {
      margin-top: 12px;
      cursor: pointer;

      &:hover {
        color: #FE6D04;
      }
    }
  }

  & p {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'cpsp' on;
    color: #333333;
  }
}
