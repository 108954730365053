.user {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0 24px 20px;
  margin-bottom: 16px;
  position: relative;
  border-radius: 12px;
  border: 1px solid #F2F2F2;
  background: #F5F4F3;
  box-shadow: 0px 4px 4px -4px rgba(0, 0, 0, 0.25);

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    & > * + * {
      margin-left: 8px;
    }
  }

  &__role {
    position: relative;
    padding: 4px 8px;
    max-width: 360px;
    border-radius: 6px;
    color: #FFFFFF;
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
    background-color: var(--bg-color);

    &.owner {
      --bg-color: #2CBA5F;
    }
    &.manager {
      --bg-color: #F2C94C;
    }
    &.administrator, &.partner {
      --bg-color: #F2994A;
    }
    &.member {
      --bg-color: #A3A3D8;
    }

    &:before {
      content:'';
      width:100%;
      height:100%;    
      position:absolute;
      left:0;
      top:0;
      background:linear-gradient(to right, transparent 328px, var(--bg-color));
    }
  }
}
