.notifications {
  overflow-y: auto;
  padding-right: 48px;
  border-radius: 32px 0 0 32px;
  position: relative;


    & >.GlobalButton {
      padding: 13px 16px 15px 16px;
      margin-top: 48px;
    }
  }

