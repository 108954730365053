.users {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  padding: 32px 0 40px 48px;
  border-radius: 32px 0 0 32px;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.20);

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;

    .GlobalButton {
      position: absolute;
      top: 28px;
      right: 48px;
      padding: 13px 16px 15px 12px;

      svg path {
        fill: #FFFFFF;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
}

.users > * {
  padding-right: 48px;
}
