body * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
}

@-moz-document url-prefix() {
  body * {
    scrollbar-width: thin;
    scrollbar-color: #BDBDBD rgba(0, 0, 0, 0);
  }
}

body .prompt {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.19);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 110;
  cursor: pointer;

  & .promptContainer {
    position: absolute;
    width: 300px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 50%;
    left: 50%;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    z-index: 12;

    &__buttonsContainer {
      display: flex;
      margin-top: 30px;

      & button {
        width: 95px;
        height: 32px;
        outline: none;
        border: none;
        border-radius: 8px;
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        color: #EAF3FF;
        cursor: pointer;
    }

      & button:first-of-type {
        background: linear-gradient(180deg, #4D76B2 0%, #6789BB 100%);
        box-shadow: 0 4px 4px -2px rgba(0, 31, 142, 0.25);
        margin-right: 15px;
      }

      & button:last-of-type {
        background: linear-gradient(180deg, #FE6D04 0%, #FA822C 100%);
        box-shadow: 0 4px 4px -2px rgba(141, 76, 0, 0.25);
      }
    }

    & p {
      line-height: 20px;
      color: #333333;
      text-align: center;
    }
  }
}

.app {
  display: flex;
  height: 100vh;
  width: 100%;
  background: #e6e6ea;
  z-index: 101;
}

.contentContainer {
  display: flex;
  width: 100%;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #FFFFFF;
  z-index: 101;
}

.contentainer {
  background: #FFFFFF;
  box-shadow: -12px 0 48px -24px rgba(0, 0, 0, 0.2);
  border-radius: 32px 0 0 32px;
  padding: 32px 48px 48px 48px;
  width: 100vw;

  & > .loaderContainer {
    height: 100%;
  }
}
