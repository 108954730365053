ol {
  list-style: none;
  counter-reset: steps;

  & > li::before {
    counter-increment: steps;
    content: counter(steps);

    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px black solid;
    border-radius: 50%;
    margin-right: 8px;
  }
}
