.logout {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.rolledUp {
    margin-left: 7px;
  }

  &_icon {
    display: flex;
  }

  & p {
    color: #E93838;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    padding-left: 6px;
  }
}
