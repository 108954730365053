@import "src/mixin.scss";

.rolesList {
  display: flex;
  flex-direction: column;

  @include _1300 {
    width: 480px;
  }

  @include _1170 {
    width: 360px;
  }

  & > h4 {
    margin-bottom: 8px;
  }

  &__list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;

    &_add {
      & .GlobalButton {
        padding: 6px 12px 6px 8px;

        svg path {
          fill: #FFFFFF;
        }
      }
    }

    &__item {
      display: flex;
      margin: 0 8px 8px 0;
      align-items: center;
      justify-items: center;
      cursor: pointer;

      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on, 'cv08' on;
      color: #4F4F4F;
      background: #FFFFFF;
      box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      padding: 6px 8px;
      max-width: 350px;
      white-space: nowrap;
      overflow: hidden;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }

      .checkmask path {
        fill: #FE6D04;
      }

      .plus path {
        fill: #4F4F4F;
      }

      &.longWord {
        position: relative;

        &:after {
          /* hide */
          content: "";
          position: absolute;
          width: 32px;
          height: 100%;
          right: 0;
          top: 0;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.67%, rgba(255, 255, 255, 0.03551) 13.33%, rgba(255, 255, 255, 0.0816599) 20%, rgba(255, 255, 255, 0.147411) 26.67%, rgba(255, 255, 255, 0.231775) 33.33%, rgba(255, 255, 255, 0.331884) 40%, rgba(255, 255, 255, 0.442691) 46.67%, rgba(255, 255, 255, 0.557309) 53.33%, rgba(255, 255, 255, 0.668116) 60%, rgba(255, 255, 255, 0.768225) 66.67%, rgba(255, 255, 255, 0.852589) 73.33%, rgba(255, 255, 255, 0.91834) 80%, rgba(255, 255, 255, 0.96449) 86.67%, rgba(255, 255, 255, 0.991353) 93.33%, white 100%);
        }
      }

      &.selectedRole {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on, 'cv08' on;
        color: #FE6D04;
        border: 1px solid #FFC093;
        background: #FFFFFF;
        box-shadow: 0 4px 4px -2px rgba(141, 76, 0, 0.2);
        border-radius: 8px;
      }
    }
  }
}