.addConnection {
  display: flex;
  flex-direction: column;

  & > p {
    height: 56px;
    padding: 16px;
    border-radius: 6px;
    background: #F4F9FF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.20) inset;
  }

  & label {
    margin-bottom: 12px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.05%;
  }

  .select {
    width: 100%;

    & input {
      width: 100%;
    }
  }

  .guidePanels {
    margin-top: 16px;
  }

  .close {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .GlobalButton {
      padding: 6px 12px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .GlobalButton {
      padding: 6px 12px;
    }
  }
}
