.amoInvoiceStatus {
  display: flex;
  flex-direction: column;
  height: 100%;

  & > h2 {
    margin-bottom: 24px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    
    &_text:last-child {
      width: 60%;
    }
  }

  & .dropdownContainer {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;    
    
    &__label {
      margin-bottom: 0;
    }
    
    &__dropdown {
      width: 60%;
    }
  }

  & > .GlobalButton {
    margin-top: 20px;
    padding: 6px 12px
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: auto;
    
    & > .GlobalButton {
      padding: 14px 16px;
      margin-bottom: 2px;
    } 
  }
}
