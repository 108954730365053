.watchVideo {

  .regularText {
    margin-bottom: 16px;

    a {
      color: #FE6D04
    }
  }

  &__buttons {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    p {
      align-self: center;
      color: #828282;
      cursor: pointer;
    }

    .GlobalButton {
      margin-left: auto;
      padding: 6px 12px;
    }
  }
}
