.chooseRole {

  .regularText {
    margin-bottom: 4px;
  }

  .smallText {
    margin-bottom: 12px;
  }

  .inputContainer {
    width: 100%;

    &__wrapper {
      width: 100%;

      input {
        width: 100%;
      }
    }
  }

  .close {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;

    .GlobalButton {
      padding: 6px 12px;
    }

    &_right {
      display: flex;

      .GlobalButton.white {
        margin-right: 16px;
      }
    }
  }
}
