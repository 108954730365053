.banking > p + p {
  margin-top: 8px;
}

.banking {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 32px 48px 40px 48px;
  border-radius: 32px 0 0 32px;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.20);

  & > p:last-of-type {
    margin-bottom: 32px;
  }

  & .dropdownContainer {
    width: 400px;
  }

  &__switch {
    display: inline-flex;
    margin-bottom: 48px;

    .GlobalButton {
      padding: 6px 12px;
      margin-left: 16px;
    }
  }

  form {
    h3:first-child {
      margin-bottom: 12px;
    }

    h3 {
      margin-bottom: 16px;
    }
  }

  & .connectionAdd__select {
    align-items: flex-end;

    & .select {

      & > label {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -0.18px;
        margin-bottom: 12px;
      }

      & > fieldset {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .connectionAdd {
    margin-bottom: 48px;
  }

  &__footer {
    display: flex;
    flex-direction: column;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.09px;

    margin-top: 20px;

    &_switch {
      display: inline-flex;
    }

    .GlobalButton {
      padding: 14px 16px;
      margin-top: 40px;
    }
  }
}
