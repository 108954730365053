@import 'src/mixin.scss';
.actionDialog {
  position: relative;
  top: 10%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  width: 534px;
  height: fit-content;
  max-height: 90%;
  overflow-y: scroll;
  padding: 20px 32px 32px 32px;
  box-shadow: 0 12px 48px -16px rgba(0, 0, 0, 0.65);
  border-radius: 12px;
  z-index: 110;

  @include mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    top: initial;
    right: initial;
    width: 100%;
    border-radius: 28px 28px 0 0;
  }

  &__container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 36px;
    box-sizing: border-box;
    z-index: 100;
    display: flex;
    justify-content: center;
  }
  &__wrapper {
    width: 100%;
    height: 100%;
    cursor: default;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;

    &.none {
      background-color: rgba(0, 0, 0, 0);
    }

    &.light {
      background: #333333;
      opacity: 0.3;
    }

    &.dark {
      background: black;
    }
  }

  & > svg {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
  }

  & > h4 {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
    margin-bottom: 8px;
  }

  &__message {
    white-space: pre-line;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
    overflow-wrap: break-word;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;

    & .GlobalButton {
      padding: 0 16px;
      min-height: 52px;
      min-width: 108px;
    }
  }
}
