.APIIntegration {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 32px 48px 40px 48px;
  border-radius: 32px 0 0 32px;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.20);

  h1 {
    margin-bottom: 16px;
  }

  .editName {
    h1 {
      margin-bottom: 0;
    }
  }

  & > p {
    margin-bottom: 24px;
  }

  &__title {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;

    & > a {
      display: inline-flex;
      text-decoration: none;
      color: #333333;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    & > a > * + * {
      margin-left: 4px;
    }

  }

  &__title > * + * {
    margin-left: 40px;
  }

  &__footer {
    margin-top: 24px;

    .GlobalButton {
      margin-bottom: 24px;
      padding: 6px 12px 6px 12px;
    }

    & .switchControl {
      margin-bottom: 40px;
    }

    &_buttons {
      display: flex;

      .GlobalButton {
        padding: 13px 16px 15px 12px;
      }

      #delete {
        svg path {
          fill: #FE6D04;
        }
      }
    }

    &_buttons > * + * {
      margin-left: 32px;
    }
  }

  & .loaderContainer {
    width: 100%;
  }
}
