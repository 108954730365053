.wabaStartList {
  width: clamp(50%, 472px, 472px);
  margin-right: 48px;
  margin-bottom: 24px;

  &__input {
    position: relative;
    margin-bottom: 24px;

    .inputContainer {
      position: relative;
      width: 100%;
      margin-bottom: 24px;

      &__wrapper {
        width: 100%;
      }

      &__input {
        width: 100%;
      }

      &__label p:first-child {
        margin-right: 24px;
      }
    }

    .deleteIcon {
      cursor: pointer;
      position: absolute;
      right: -28px;
      bottom: 20px;
      width: 16px;
      height: 16px;

      path {
        fill: #F54242;
      }
    }
  }

  .GlobalButton {
    padding: 6px 12px 6px 8px;

    path {
      fill: #FE6D04;
    }
  }
}
