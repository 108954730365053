@import 'src/mixin.scss';

.subscription-card {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  position: relative;
  @include desktop {
    padding: 20px 52px 0 24px;
    min-height: 100px;
  }
  @include mobile {
    padding: 20px 16px 0 20px;
    min-height: 148px;
  }
  background: #F5F4F3;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  @include mobile {

    & form {
      .listiconContainer__list {
        margin-right: 0;
        height: 190px;
      }
    }
  }

  &__content {
    width: 100%;
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #333333;
    margin-bottom: 8px;
  }

  &__connection {
    color: #333333;
    @include desktop {
      margin-bottom: 8px;
    }
    @include mobile {
      margin-bottom: 8px;
    }
  }

  &__tariff {
    margin-top: 16px;
    margin-bottom: 4px;

    @include mobile {
      margin-top: 8px;
    }
  }

  &__status {
    margin-bottom: 12px;
    @include mobile {
      margin-bottom: 40px;
    }

    &.paid, &.test {
      color: #333333;
    }

    &.warn, &.expired, &.testEnd {
      color: #F54242;
    }

    &.paused {
      color: #4F4F4F;
    }
  }

  &__select {
    width: 24px;
    height: 24px;

    @include desktop {
      margin-left: -8px;
      margin-right: 12px;
    }

    @include mobile {
      margin-left: -4px;
      margin-right: 12px;
    }

    & > :disabled {
      color: gray;
    }
  }

  &__icon {
    display: flex;
    position: absolute;
    top: 20px;
    right: 20px;

    & svg {
      width: 36px;
      height: 36px;

      path {
        fill:  #FFFFFF;
      }
    }
  }

  &__buttonsGroup {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    align-self: self-end;
    justify-content: space-around;
    width: 119px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 12px 0 12px 0;

    & > button {
      cursor: pointer;
      display: flex;
      align-items: center;
      background: #FFFFFF;
      border: none;
      outline: none;
      color: #333333;

      & > svg {
        margin-right: 4px;
        width: 16px;
        height: 16px;
      }
    }

    & > button:disabled {
      color: #d9d0d0;

      & > svg path {
        fill: #d9d0d0;
      }
    }
  }
}
