@import 'src/mixin.scss';

.requisitesForm {
  padding: 20px 24px 24px 24px;
  border: 1px solid #f2f2f2;
  background: #fff;
  box-shadow: 0 100px 80px 0 rgba(0, 0, 0, 0.07), 0 41.778px 33.422px 0 rgba(0, 0, 0, 0.05), 0 22.336px 17.869px 0 rgba(0, 0, 0, 0.04), 0 12.522px 10.017px 0 rgba(0, 0, 0, 0.04), 0 6.65px 5.32px 0 rgba(0, 0, 0, 0.03), 0 2.767px 2.214px 0 rgba(0, 0, 0, 0.02);
  z-index: 1;

  @include desktop {
    position: absolute;
    top: 24px;
    right: 40px;
    width: 312px;
    border-radius: 12px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    position: relative;

    h3 {
      margin-bottom: 0 !important;
    }

    @include desktop {
      margin-bottom: 16px;
    }
    @include mobile {
      margin-bottom: 20px;
    }

    svg {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      width: 20px;
      height: 20px;
    }
  }

  &__content {

    @include mobile {
      &::before {
        content: '';
        display: block;
        position: fixed;
        left: 0;
        width: 100%;
        border-bottom: 1px solid #E0E0E0;
      }

      .inputContainer:first-of-type {
        padding-top: 20px;
      }

      .inputContainer {
        width: 100%;

        &__wrapper {
          width: 100%;
        }

        &__input {
          width: 100%;
          height: 56px;
          padding-left: 12px;
          font-size: 16px;
        }
      }
    }

    @include desktop {
      .inputContainer {

        &__label {
          margin-bottom: 4px;
          font-size: 14px;
        }

        &__input {
          height: 36px;
          padding: 8px 12px;
          font-size: 16px;
        }
      }
    }
  }

  &__buttons {
    display: flex;

    @include desktop {
      justify-content: space-between;

      .GlobalButton {
        padding: 6px 12px;
      }
    }
    @include mobile {
      flex-direction: column-reverse;

      .GlobalButton {
        width: 100%;
        padding: 13px 16px 15px 16px;

        &:last-child {
          margin-bottom: 16px;
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .GlobalButton {
      padding: 6px 12px 6px 12px;

      @include desktop {
        padding: 6px 12px;
      }
      @include mobile {
        width: 100%;
        padding: 13px 16px 15px 16px;
      }
    }
  }
}
