.popupApiKey {
  display: flex;
  flex-flow: column;

  & h2, p {
    margin-bottom: 8px;
  }

  & input {
    display: inline-flex;
    height: 56px;
    padding: 16px 5px 16px 7px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #333333;
    outline: none;
    border: none;
    font-family: Inter, sans-serif;
    border-radius: 6px;
    background: #F4F9FF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.20) inset;
  }

  .GlobalButton {
    display: flex;
    padding: 13px 16px 15px 12px;
    margin-top: 20px;

    & > svg {
      & path {
        fill: #FFFFFF;
      }
    }
  }
}
