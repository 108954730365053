.guidePanel {
  cursor: pointer;
  display: flex;
  padding: 16px 20px 16px 16px;
  border-radius: 16px;
  border: 1px solid #2CBA5F;
  margin-bottom: 8px;

  &.complete {
    pointer-events: none;
    background: #EFF9F3;
  }

  &.disable {
    pointer-events: none;
    border: 1px solid #ebebeb;
  }

  &.disable > &__number {
    border: 1px solid #EBEBEB;
    color: #D1D1D1;
  }

  &.complete > &__number {
    border: none;
    color: transparent;
    background: #2CBA5F;

    svg {
      position: absolute;
      width: 16px;
      height: 16px;

      path {
        fill: #FFFFFF;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 16px;
    color: #2CBA5F;
    border: 1px solid #2CBA5F;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.07px;
  }

  &__title {
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.09px;
  }

  &__documentation {
    cursor: pointer;
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid gray;
    margin-top: 12px;
    color: #333333;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.07px;

    &:hover {
      transition-duration: 301ms;
      background: #d3d3d338;
      border-radius: 4px;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  &__subtitle {
    margin-top: 12px;
  }

  &__arrow {
    margin-left: auto;
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
  }
}
