.mobileInfo {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-height: 40%;
  padding: 24px 24px 56px 24px;
  background: #FFFFFF;
  border-radius: 28px 28px 0 0;
  box-shadow: 0 12px 48px -16px rgba(0, 0, 0, 0.65);
  z-index: 120;
  display: grid;
  grid-template-columns: auto 15.3px;

  &__message {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'cv05' on, 'cv09' on, 'cv02' on, 'cv01' on;
    color: #333333;
  }

  svg {
    position: absolute;
    cursor: pointer;
    top: 21px;
    right: 24px;
    width: 24px;
    height: 24px;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    cursor: default;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;

    &.none {
      background-color: rgba(0, 0, 0, 0);
    }

    &.light {
      background: #333333;
      opacity: 0.3;
    }

    &.dark {
      background: black;
    }
  }
}
