@import 'src/mixin.scss';

.subscriptions {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.20);

  @include desktop {
    position: relative;
    overflow-y: hidden;
    padding: 32px 0 40px 48px;
    border-radius: 32px 0 0 32px;
  }

  @include mobile {
    padding: 20px 20px 24px 20px;
  }

  &__header {
    display: flex;
    flex-direction: column;

    & a {
      @include desktop {
        margin-left: 64px;
      }

      @include _1300 {
        margin-left: 24px;
      }

      @include mobile {
        margin-left: 0;
      }

      svg {
        width: 18px;
        height: 18px;
        margin-right: 8px;

        path {
          fill: #FE6D04;
        }
      }

      display: flex;
      align-items: center;
      width: fit-content;
      text-decoration: none;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'cv05' on, 'cv09' on, 'cv02' on, 'cv01' on;
      color: #FE6D04;

      p {
        border-bottom: 1px solid #FE6D04;
      }
    }

    &_title {
      display: flex;

      @include desktop {
        align-items: center;
        margin-bottom: 24px;
      }
      @include mobile {
        flex-direction: column;
        margin-bottom: 28px;

        h1 {
          margin-bottom: 16px;
        }
      }
    }
  }

  &__content {
    @include desktop {
      overflow-y: auto;
      height: calc(100vh - 196px);
      padding-right: 48px;

      &.extended {
        height: calc(100vh - 176px);
        margin-top: 24px;
      }
    }

    & > .GlobalButton {
      padding: 13px 16px 15px 16px;

      @include desktop {
        position: absolute;
        top: 28px;
        right: 48px;
      }
      @include mobile {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }

  &__content:has(> .subRequisitesList) {
    @include desktop {
      overflow-y: hidden;
      padding-right: 0;
    }
  }

  &__button_disable {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 16px 15px;
    gap: 8px;
    width: 117px;
    height: 52px;
    @include desktop {
      position: absolute;
      top: 28px;
      right: 48px;
    }
    @include mobile {
      width: 100%;
      margin-bottom: 24px;
    }
    background: #D1D1D1;
    border-radius: 10px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'cpsp' on;
    color: #828282;
  }

  & .switchTabs {
    @include desktop {
      margin-bottom: 24px;
    }
    @include mobile {
      width: 100%;
      height: 28px;
      padding: 1px;
      margin-bottom: 20px;
      background: #E7E7E7;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
    }

    & > button {
      @include desktop {
        padding: 7px 12px;
        margin-right: 4px;
        font-weight: 500;
      }
      @include mobile {
        width: 50%;
        padding: 4px 0 4px 0;
        margin: 0;
        justify-content: center;
        font-weight: 400;
        border-radius: 6px;
        background: #E7E7E7;
      }
      font-size: 14px;
      line-height: 20px;
    }

    @include mobile {
      & > button:last-child {
        margin: 0;
      }
      & > button.active {
        background: #FFFFFF;
        border: 1px solid #E6E6E6;
        box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        color: #252525;
      }
    }
  }

  &__requisites {
    &_add {
      position: relative;
    }
  }
}

.SubscriptionsPlug {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  & img {
    margin-top: 72px;
    margin-bottom: 16px;

    @include mobile {
      width: 240px;
      height: 240px;
    }
  }

  & p {
    margin-bottom: 16px;

    @include mobile {
      width: 100%;
    }
  }

  & a {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'cv05' on, 'cv09' on, 'cv02' on, 'cv01' on;
    text-decoration: none;
    color: #FE6D04;

    svg {
      width: 18px;
      height: 18px;
      margin-right: 8px;

      path {
        fill: #FE6D04;
      }
    }

    p {
      margin-bottom: 0;
      border-bottom: 1px solid #FE6D04;
    }
  }

  & button {
    margin-top: 56px;
    margin-bottom: 108px;
    padding: 13px 16px 15px 16px !important;

    @include mobile {
      width: 100%;
    }
  }

  &__description {
    width: 460px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #333333;
  }
}

.requisitesPlug {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @include desktop {
    margin-top: 114px;
  }
  @include mobile {
    margin-top: 120px;
    height: calc(100vh - 320px);
    justify-content: space-between;
  }

  .GlobalButton {
    padding: 13px 16px 15px 16px;
    @include desktop {
      margin-top: 56px;
    }
    @include mobile {
      width: 100%;
      margin-top: auto;
    }
  }

  &_text {
    display: flex;
    flex-direction: column;
    width: 80%;
    text-align: center;
    align-items: center;

    & > p {
      @include desktop {
        font-size: 18px;
      }
      @include mobile {
        font-size: 14px;
        line-height: 24px;
      }
      font-style: normal;
      font-weight: 400;
      text-align: center;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
      color: #333333;
    }

    & > p:first-child {
      @include mobile {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 16px;
      }
      @include desktop {
        font-size: 32px;
        line-height: 44px;
        margin-bottom: 24px;
      }
      font-weight: 700;
      letter-spacing: -0.01em;
    }
  }
}

.load {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: default;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0);
}

@include mobile {
  table {
    width: 100%;
    border: 0;
  }

  thead {
    display: none;
  }

  tr {
    display: flex;
    flex-direction: column;
  }

  td::before {
    content: attr(data-title);
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 4px;
  }

  td {
    display: block;
    text-align: left !important;
    overflow-wrap: anywhere;
    margin-bottom: 12px;
  }

  td:last-child {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    border-bottom: 1px solid #E6E6EA;
  }
}
