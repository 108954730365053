.notificationCardEvents {
  display: flex;
  flex-direction: column;

  .isoSwitch {
    margin: 0 12px 0 0;
    min-width: 40px;
  }
}

.notificationCardEvents > * + * {
  margin-top: 16px;
}
