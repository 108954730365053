@import 'src/mixin.scss';

.popupWithTail {
  position: relative;
  display: inherit;
  margin-left: 8px;

  & > span {
    display: inline-flex;
    align-items: center;

    & svg {
      width: 16px;
      height: 16px;

      path {
        fill: #FE6D04;
      }
    }
  }

  &_message {
    display: none;
    position: absolute;
    top: -40%;
    transform: translate(-78%, -100%);
    min-width: 265px;

    padding: 18px;
    box-shadow: 0 12px 48px -16px rgba(0, 0, 0, 0.65);
    border-radius: 12px;
    background-color: white;

    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #333333;
  }

  &_tail {
    position: absolute;
    width: 16px;
    height: 11px;
    right: 42px;
    bottom: -9px;
  }

  @include desktop {
    &_icon:hover ~ .popupWithTail_message {
      display: block;
    }
  }
}
