@import 'src/mixin.scss';

.subRequisiteItem {

  @include desktop {
    display: flex;
    justify-items: start;
    padding-top: 20px;
    padding-bottom: 16px;
    border-bottom: 1px #E6E6EA solid;
    border-radius: 1px;

    & > div + div {
      margin-left: 16px;
    }

    & > div:nth-child(1) {
      flex-basis: 264px;
    }

    & > div:nth-child(2) {
      flex-basis: 148px;
    }

    & > div:last-child {
      flex-grow: 1;
    }
  }

  @include mobile {
    & > div::before {
      content: attr(data-title);
      display: block;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 4px;
    }

    & > div + div {
      margin-top: 12px;
    }
  }

  .GlobalButton {
    padding: 6px 8px 6px 8px;

    &:first-child {
      border: 1px solid #E0E0E0;
    }

    &:last-child {
      border: 1px solid #FFB2B2;
    }
  }

  &__status {
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
      padding-bottom: 16px;
      border-bottom: 1px #E6E6EA solid;
    }

    &.approved {
      color: #2CBA5F;
    }

    &.pending {
      color: #333333;
    }

    &.declined {
      color: #EB5757;
    }

    &_buttons {
      display: flex;

      #edit {
        margin-right: 8px;
      }

      #delete svg path {
        fill: #F54242;
      }

      @include mobile {
        margin-top: 12px;
      }
    }
  }
}
