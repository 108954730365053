.permissionItem {
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 4px;
  }

  & > p {
    margin-left: 28px;
  }

  &__items {
    display: flex;
    margin-top: 8px;

    & > * + * {
      margin-left: 16px;
    }

    &_label {
      display: inline-flex;
    }
  }
}
