@import '../../mixin';

.questioning {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #F2F2F2;
  background: #FFF;
  box-shadow: 0 100px 80px 0 rgba(0, 0, 0, 0.07), 0 41.778px 33.422px 0 rgba(0, 0, 0, 0.05), 0 22.336px 17.869px 0 rgba(0, 0, 0, 0.04), 0 12.522px 10.017px 0 rgba(0, 0, 0, 0.04), 0 6.65px 5.32px 0 rgba(0, 0, 0, 0.03), 0 2.767px 2.214px 0 rgba(0, 0, 0, 0.02);
  z-index: 10;

  @include desktop {
    width: 480px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: fit-content;
    padding: 20px 32px 24px 32px;
    border-radius: 12px;
  }

  @include mobile {
    width: 100%;
    height: 100%;
    padding: 20px 20px 16px 20px;
    overflow-y: auto;

    form {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__header {
    display: flex;

    h2 {
      margin: 0 16px 16px 0;
  
      @include mobile {
        line-height: 30px;
        letter-spacing: -0.12px;
        margin: 0 16px 16px 0;
      }
    }

    .closeIcon {
      @include desktop {
        min-width: 24px;
        min-height: 24px;
        margin-right: -16px;
      }
      @include mobile {
        right: 12px;
        border-radius: 32px;
        background: #E6E6EA;
        box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.15);
        min-width: 56px;
        min-height: 56px;
        padding: 16px;
      }
    }
  }

  .GlobalButton {
    padding: 6px 12px;
    margin-left: auto;

    @include mobile {
      width: 100%;
      padding: 13px 16px 15px 16px;
      margin-top: auto;
    }
  }

  .inputContainer {
    width: 100%;

    &__wrapper, &__input {
      width: 100%;
    }
  }

  .dropdownContainer__label, .inputContainer__label p {
    line-height: 24px;
    letter-spacing: -0.09px;
  }

  &__wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #1C2027;
    z-index: 10;
  }

  &__role {
    .dropdownContainer:last-child {
      margin-bottom: 0;
    }

    .inputContainer:last-child {
      margin-bottom: 0;
    }

    @include desktop {
      margin-bottom: 24px;
    }
    @include mobile {
      margin-bottom: 32px;
    }
  }
}
